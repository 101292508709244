<template>
  <div>
    <Wrapper>
      <Sidebar slot="sidebar"/>
      <Navbar slot="navbar"/>
      <Footer slot="footer"/>
      <div slot="content">
        <!-- <input type="text" v-model="filters.name.value"> -->
          <!-- <div class="pre-loader text-center" v-if="preLoader">
                    <span class="loader"></span>
          </div>-->
       
        <div class="container">

<div style="display:flex; min-height:70vh; align-items:center">
<div class="row mt-4">

<div class="col-md-7 col-12">
   <div class=" text-center">

                  <div>
                  <img src="../../../assets/images/bookBig.png" class="mr-4 mb-4" height="50">
                  <span><b>Booking</b></span>
                  </div>
                  <img src="../../../assets/images/handshake.png" class="mt-4 img-fluid" >
                </div>
</div>
<div class="col-md-5 col-12">
  <div class="booking-card mt-2 mb-2 point-cursor" @click="goBooking" style="display:flex; justify-content:center; align-items:center">
<p style="color:#0094FF">Continue</p>  <img src="../../../assets/images/rightarrowb.png" class="ml-4"  height="10">
</div>
<div class="booking-card">
    <img src="../../../assets/images/bookcol.png" class="mb-3" height="40">
    <p style="font-size:16px; color:#828282">Book funds from your wallet or an investment and earn an interest.</p>
    <hr>
    <img src="../../../assets/images/deeproots.png"  class="mb-3" height="40">
    <p style="font-size:16px; color:#828282">Create deep roots in your financial portfolio and grow your wealth.</p>
    <hr>
    <img src="../../../assets/images/autobook.png"  class="mb-3" height="40">
    <p style="font-size:16px; color:#828282">Never leave your funds idle by Autobooking using your portfolio, cards or wallet</p>
    <hr>
    <img src="../../../assets/images/withdrawRound.png"  class="mb-3" height="40">
    <p style="font-size:16px; color:#828282">Withdraw anytime.</p>
</div>

</div>

</div>
</div>
</div>

    
      </div>
    </Wrapper>
  </div>
</template>

<script>
import Navbar from "../../partials/Navbar";
import Sidebar from "../../partials/Sidebar";
import Footer from "../../partials/Footer";
import Wrapper from "../../partials/Wrapper";
import trans from "../../../../translations";

import { commonJs } from "../mixins/commonJs.js";

import { mapState } from "vuex";

import moment from "moment";

export default {
  mixins: [commonJs],
  data() {
    return {
      tran: {}
    };
  },
  methods: {
    goBooking() {
      this.$router.push("/book");
    },
   
  },
  components: {
    Navbar,
    Footer,
    Sidebar,
    Wrapper
  },

  computed: {
    ...mapState({
      userBal: state => state.auth.userProfileInfo.balance,
 
    }),

    returnAmountOfPenalty() {
      return this.penaltyAmount;
    }
  },
  mounted() {
    let heading = {
      main: this.tran.booking
    };

    this.$store.commit("market/savePageHeading", heading);
      this.$store.commit(
      "auth/saveBookRouteVisited",
      this.$route.path
    );
  },


};
</script>

<style scoped>
.booking-card {
  background: #fff;
  min-height: 50px;
  border-radius: 10px;
  padding: 10px;

  overflow: hidden !important;
}
.book-home-cover {

  min-height: 70vh;
  display: flex;
  align-items: center;
    padding-bottom: 5%;
}
</style>

<template>
<div>
    <bookingHomeExplainer />
</div>
</template>

<script>
import bookingHomeExplainer from '../../components/dashboard/booking/BookExplainer'
export default {
    components: {
        bookingHomeExplainer
    }
}
</script>

<style>
